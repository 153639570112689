import blog1 from "../assets/blog/01.jpg";
import blog2 from "../assets/blog/02.jpg";
import blog3 from "../assets/blog/03.jpg";
import blog4 from "../assets/blog/04.jpg";
import blog5 from "../assets/blog/05.jpg";
import blog6 from "../assets/blog/06.jpg";
import blog7 from "../assets/blog/07.jpg";
import blog8 from "../assets/blog/08.jpg";
import icon12 from '../assets/svg/icon12.svg'
import icon13 from '../assets/svg/icon13.svg'
import icon14 from '../assets/svg/icon14.svg'
import logo from '../assets/logo2.png'
import sideImg from '../assets/sideImg.png'
import aboutUs from '../assets/aboutUs.png'

const words = [
    "Difference Quotient Calculator",
    "Critical Points Calculator",
    "Local Maxima & Minima Calculator",
    "Inflection Point Calculator",
    "Interval notation Calculator",
    "Comparing Fractions Calculator",
    "Eigenvalues & Eigenvectors Calculator",
    "Composite Function Calculator",
    "Operations on Functions Calculator",
    "Point Slope Calculator",
    "Inverse Function Calculator",
    "Log Calculator",
    "Vertex Calculator",
    "Vector Addition Calculator",
    "Subset Calculator",
];
const trignoFunction = [
    { label: 'sin(x)', value: '1' },
    { label: 'cos(x)', value: '2' },
    { label: 'tan(x)', value: '3' },
    { label: 'sec(x)', value: '4' },
    { label: 'csc(x)', value: '5' },
    { label: 'cot(x)', value: '6' },
]
const degree = [
    { label: 'radian', value: 'radian' },
    { label: 'degree', value: 'degree' },
]
const servies = [
    {
        inputLabel: ['Enter function', 'Enter Point'],
        inputKeys: ['Inp1', 'Inp2'],
        inputSize: "2",
        params: "diffquotient",
        allowedCharacters: {
            Inp1: { regex: /^[+\-*/^0-9\s]*$/, restrict: true }, // Apply restriction for field1
            Inp2: { regex: /^[0-9\s]*$/, restrict: false }, // No restriction for field2
        },
        title: "Difference Quotient Calculator",
        description: "Calculate the difference quotient of a function."
    },
    {
        inputLabel: ['Enter function'],
        inputKeys: ['inp1'], inputSize: "1",
        params: "minimaandmaxima",
        title: "Local Maxima & Minima Calculator",
        description: "Discover the local maxima and minima of a function."
    },
    {
        inputLabel: ['Enter Function(f)', 'Enter Functiong(g)', 'Enter Function(h)'],
        inputKeys: ['exp1', 'exp2', 'exp3'],
        inputSize: "3",
        params: "compositefunction",
        title: "Composite Function Calculator",
        description: "Evaluate composite functions effortlessly."
    },
    {
        inputLabel: ['Enter Function(f)', 'Enter Functiong(g)'],
        inputKeys: ['Ent', 'Ent1', 'point'],
        inputSize: "3",
        params: "operationfunction",
        title: "Operations on Functions Calculator",
        description: "Perform various operations on functions, such as addition, subtraction, multiplication, and division."
    },
    {
        inputLabel: ['Enter Function'],
        inputKeys: ['Exp'],
        inputSize: "1",
        params: "inversefunction",
        title: "Inverse Function Calculator",
        description: "Find the inverse function of a given function."
    },
    {
        inputLabel: ['Enter Function'],
        inputKeys: ['inp'],
        inputSize: "1",
        params: "vectormagnitude",
        title: "Vertex Calculator",
        description: "Determine the vertex of a parabola."
    },
    {
        inputLabel: ['Enter Function'],
        inputKeys: ['ent'],
        inputSize: "1",
        params: "subsetcalc",
        title: "Subset Calculator",
        description: "Work with subsets of a set."
    },
    {
        inputLabel: ['vector A (i)', 'vector A (j)', 'vector A (k)', 'vector B (i)', 'vector B (j)', 'vector B (k)'],
        inputKeys: ['Ent1', 'Ent2', 'Ent3', 'Ent4', 'Ent5', 'Ent6'],
        inputSize: "6",
        params: "vectoraddition",
        title: "Vector Addition Calculator",
        description: "Add vectors together using this calculator."
    },
    {
        inputLabel: [],
        inputKeys: [],
        inputSize: "",
        params: "interval-notation",
        title: "Interval notation Calculator",
        description: "Work with interval notation for sets of real numbers."
    },
    {
        inputLabel: [],
        inputKeys: ['function', 'angle_vals', 'angle_ops'],
        inputSize: "2",
        params: "cofunction",
        title: "Cofunction Calculator",
        description: "A trigonometric function whose value for the complement of an angle is equal to the value of a given trigonometric function of the angle itself"
    },
    {
        inputLabel: [],
        inputKeys: [],
        inputSize: "",
        params: "1",
        title: "Critical Points Calculator",
        description: "Find the critical points of a function."
    },
    {
        inputLabel: [],
        inputKeys: [],
        inputSize: "",
        params: "2",
        title: "Inflection Point Calculator",
        description: "Locate the inflection point of a function."
    },
    {
        inputLabel: [],
        inputKeys: [],
        inputSize: "",
        params: "4",
        title: "Comparing Fractions Calculator",
        description: "Compare fractions with ease using this calculator."
    },
    {
        inputLabel: [],
        inputKeys: ['inp1'],
        inputSize: "1",
        params: "eigenval",
        title: "Eigenvalues & Eigenvectors Calculator",
        description: "Compute eigenvalues and eigenvectors of a matrix."
    },
    {
        inputLabel: ['X1', 'Y1', 'Slope (m)'],
        inputKeys: ['x', 'y', 'm'],
        inputSize: "3",
        params: "pointslope",
        title: "Point Slope Calculator",
        description: "Calculate the point-slope form of a linear equation."
    },
    {
        inputLabel: [],
        inputKeys: [],
        inputSize: "",
        params: "7",
        title: "Log Calculator",
        description: "Perform logarithmic calculations with precision."
    },
];

const faqsData = [
    {
        question: "What is theMATHEX?",
        answer: "theMATHEX is an online platform that provides tools and resources, including calculator tools, to help users with various tasks. By using theMATHEX, users agree to comply with the terms and privacy policies outlined on our website."
    },
    {
        question: "How does theMATHEX collect my personal information?",
        answer: "We collect personal information through interactions like visiting our website, subscribing to newsletters, and filling out forms. The information collected may include your name, email address, and browsing data, such as time spent on the site or pages viewed."
    },
    {
        question: "What are cookies, and how does theMATHEX use them?",
        answer: "Cookies are small files placed on your device to track your browsing activity. theMATHEX uses cookies to enhance the user experience, analyze site performance, and deliver personalized content. You can disable cookies in your browser settings, but some parts of the site may not function properly."
    },
    {
        question: "How can I change my cookie preferences?",
        answer: "You can modify your cookie preferences through your browser settings by disabling or deleting cookies. Please note that some site features may not function properly if cookies are disabled."
    },
    {
        question: "How do I contact theMATHEX for privacy-related concerns?",
        answer: "You can contact us at themathex512@gmail.com if you have any questions or concerns regarding our privacy policy or the way we handle your data."
    },
    {
        question: "Can the Terms and Conditions or Privacy Policy change?",
        answer: "Yes, we reserve the right to modify our Terms and Privacy Policy at any time. We recommend you regularly review these policies to stay informed about any changes. If significant changes are made, we will notify users via our website."
    },
    {
        question: "What happens if I violate the Terms and Conditions?",
        answer: "If you violate the Terms, theMATHEX reserves the right to terminate or suspend your access to the site and its services without prior notice."
    },
    {
        question: "How does theMATHEX handle legal disclaimers?",
        answer: "Our calculator tools and site content are provided 'as is' without any warranties. We do not guarantee that the results from our calculators will be 100% accurate. theMATHEX is not responsible for any damages arising from the use of our tools."
    }
];

export {
    blog1,
    blog2,
    blog3,
    blog4,
    logo,
    blog5,
    blog6,
    blog7,
    blog8,
    words,
    icon14,
    aboutUs,
    icon13,
    icon12, servies,
    trignoFunction,
    degree,
    sideImg,
    faqsData
}